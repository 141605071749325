import { QueryHookOptions, useQuery as useDeprecatedDoNotUseGraphQlQuery } from "@apollo/client";
import { useSession } from "@src/app/store/helperHooks";
import { MappingLevel } from "@src/lib/constants";
import { Shift } from "@src/lib/interface";
import { forEach } from "lodash";
import { useEffect, useState } from "react";

import { GET_REQUIREMENTS_STATUS } from "../../documents/gql";
import { CbhDocument } from "../model";

export interface UseMissingDocumentsResult {
  loading?: boolean;
  cbh?: Set<string>;
  state?: Set<string>;
  msa?: Set<string>;
  county?: Set<string>;
  hcf?: Set<string>;
  pendingDocuments: Set<string>;
  missingDocuments: Set<CbhDocument>;
  covid?: string;
  mostRecentCovidDoc?: any;
}

export function useMissingDocuments(
  shift: Shift | null | undefined,
  queryOptions: QueryHookOptions = {}
): UseMissingDocumentsResult {
  const { userId: hcpId } = useSession();
  const { data, loading } = useDeprecatedDoNotUseGraphQlQuery(GET_REQUIREMENTS_STATUS, {
    variables: { hcpId },
    ...queryOptions,
  });
  const [status, setStatus] = useState<UseMissingDocumentsResult>({
    loading,
    pendingDocuments: new Set(),
    missingDocuments: new Set(),
  });

  const { hcpRequirementStatus } = data || {};
  /**
   * Refactor this useEffect to be part of `useQuery`.
   */
  useEffect(() => {
    if (!shift || loading) {
      return;
    }

    const cbh = new Set<string>();
    const state = new Set<string>();
    const msa = new Set<string>();
    const county = new Set<string>();
    const hcf = new Set<string>();

    const pendingDocuments = new Set<string>();
    const completed = new Set(hcpRequirementStatus?.completed);
    const missingDocuments = new Set<CbhDocument>();
    forEach(hcpRequirementStatus?.requirements || [], (req) => {
      if (completed.has(req.reqId) || pendingDocuments.has(req.reqId)) {
        return;
      }

      if (
        Array.from(missingDocuments).some((missingDocument) => missingDocument.reqId === req.reqId)
      ) {
        return;
      }

      if (!shift.facility || !shift.facility.fullAddress) {
        return;
      }

      const isDocumentRequiredforFacilityType =
        req.hcfType.includes(shift.facility.type) &&
        (req.requiredBy.includes(shift.facility.fullAddress.state) ||
          req.requiredBy.includes(shift.facility.fullAddress.metropolitanStatisticalArea) ||
          req.requiredBy.includes(shift.facility.fullAddress.region));
      const isDocumentRequired =
        isDocumentRequiredforFacilityType ||
        req.requiredBy.includes(shift.facility.userId) ||
        req.level === MappingLevel.GLOBAL;
      if (isDocumentRequired && !hcpRequirementStatus?.pending.includes(req.reqId)) {
        missingDocuments.add(req);
      }

      if (isDocumentRequired && hcpRequirementStatus?.pending.includes(req.reqId)) {
        pendingDocuments.add(req.name);
      }
    });

    setStatus({
      ...(cbh.size && { cbh }),
      ...(state.size && { state }),
      ...(msa.size && { msa }),
      ...(county.size && { county }),
      ...(hcf.size && { hcf }),
      missingDocuments,
      pendingDocuments,
      loading,
    });
  }, [hcpRequirementStatus, loading, shift]);

  return status;
}
