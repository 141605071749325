import "./style.scss";
import { isDefined } from "@clipboard-health/util-ts";
import { IonSpinner } from "@ionic/react";
import { IShiftSlot } from "@src/app/components/workWithFriends/interface";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { BookabilityAttributes } from "@src/appV2/OpenShifts/ShiftAction";
import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import { SEARCH_MODE } from "@src/constants";
import { Shift } from "@src/lib/interface";
import { useQuery } from "@tanstack/react-query";
import { FC, useCallback, useEffect, useState } from "react";

import { allowFacilityDetailsSearchModes } from "./constants";
import { HcfProfileSource } from "./model";
import { addAgentActivityLog, fetchInstantBookShifts } from "../api";
import { HCP_ACTIONS } from "../constants";
import { ShiftListGroupState } from "../model";
import { ShiftItem } from "../shiftItem";

type FacilityShiftItemProps = {
  day: string;
  shift: Shift;
  shifts: ShiftListGroupState;
  getFacilityShifts: () => Promise<any>;
  setClickedInterestInShift: (value: boolean) => void;
  isHcpInstantPayEnabled?: boolean;
  searchMode?: SEARCH_MODE;
  availableShiftIds?: string[];
  filterDistance?: number;
  filterDays?: number;
  hcfProfileSource?: HcfProfileSource;
  shiftSlots?: IShiftSlot[];
  allowedAgentReq?: string[];
  onFacilityDetailsClick?: (
    shift: Shift,
    isInstantBook: boolean,
    displayExtraTimePayCard?: boolean
  ) => void;
  shiftBookability: BookabilityAttributes;
  isShiftBookabilityLoading: boolean;
};

const FacilityShiftItem: FC<FacilityShiftItemProps> = ({
  day,
  shift,
  shifts,
  getFacilityShifts,
  setClickedInterestInShift,
  isHcpInstantPayEnabled,
  searchMode,
  availableShiftIds,
  filterDistance,
  filterDays,
  hcfProfileSource,
  shiftSlots,
  allowedAgentReq,
  onFacilityDetailsClick,
  shiftBookability,
  isShiftBookabilityLoading,
}) => {
  const agent = useDefinedAgent();
  const [shiftName, setShiftName] = useState<ShiftPeriod | undefined | null>(null);

  // Given the probability of the FF and a random probability , this decides whether to
  // make the api call to the Main-API or the BFF-API

  useEffect(() => {
    setShiftName(shift.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isInstantBookShiftsFeatureFlagEnabled = useCbhFlag(
    CbhFeatureFlag.GET_INSTANT_BOOK_SHIFTS_ENABLED,
    { defaultValue: false }
  );

  const {
    data: instantBookShifts = [],
    isLoading: isLoadingCheckInstantBook,
    isSuccess: isShiftLoaded,
  } = useQuery({
    queryKey: ["getInstantBookShifts", day],
    queryFn: async () => {
      const instantbookShiftsLoaded = await fetchInstantBookShifts(day);

      return (instantbookShiftsLoaded || []).filter((shift) => shiftName === shift.name);
    },
    enabled: isDefined(shiftName) && isDefined(day) && isInstantBookShiftsFeatureFlagEnabled,
  });

  // Logger
  const logAgentActivity = useCallback(
    (shiftsToLog: any[], action: string, shiftId = null) => {
      if (agent.userId && day && shiftName && !isLoadingCheckInstantBook && isShiftLoaded) {
        const agentActivityLog = {
          day: day,
          shiftId,
          timeSlot: shiftName.toUpperCase(),
          agentId: agent.userId,
          shifts: shiftsToLog,
          action,
        };
        addAgentActivityLog(agentActivityLog);
      }
    },
    [agent.userId, day, shiftName, isLoadingCheckInstantBook, isShiftLoaded]
  );

  useEffect(() => {
    if (!isInstantBookShiftsFeatureFlagEnabled || (!isShiftLoaded && isLoadingCheckInstantBook)) {
      return;
    }
    let currentIndex = 1;
    const shiftsToLog = [...shifts.open, ...shifts.assigned].reduce((filtered: Shift[], option) => {
      if (isDefined(option)) {
        const shift = {
          ...option,
          position: currentIndex,
          isInstantBook: instantBookShifts.findIndex((a: Shift) => a.shiftId === option._id) > -1,
        };
        filtered.push(shift);
        currentIndex = currentIndex + 1;
      }
      return filtered;
    }, []);
    logAgentActivity(shiftsToLog, HCP_ACTIONS.TIME_SLOT_CLICK);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShiftLoaded, isLoadingCheckInstantBook, isInstantBookShiftsFeatureFlagEnabled]);

  const facilityDetailsPageIsEnabled =
    isDefined(searchMode) && allowFacilityDetailsSearchModes.includes(searchMode);

  if (!isInstantBookShiftsFeatureFlagEnabled || (!isLoadingCheckInstantBook && isShiftLoaded)) {
    return (
      <ShiftItem
        shift={shift}
        shiftName={shiftName as string}
        updateShiftList={getFacilityShifts}
        setClickedInterestInShift={setClickedInterestInShift}
        isHcpInstantPayEnabled={isHcpInstantPayEnabled}
        showFacilityDetails={facilityDetailsPageIsEnabled}
        availableShiftIds={availableShiftIds}
        searchMode={searchMode}
        filterDistance={filterDistance}
        filterDays={filterDays}
        hcfProfileSource={hcfProfileSource}
        shiftSlots={shiftSlots}
        allowedAgentReq={allowedAgentReq}
        onFacilityDetailsClick={onFacilityDetailsClick}
        shiftBookability={shiftBookability}
        isShiftBookabilityLoading={isShiftBookabilityLoading}
        isForFacilityOpenShift={true}
      />
    );
  }
  return (
    <div
      style={{
        textAlign: "center",
        padding: "4em 4em",
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      <IonSpinner />
    </div>
  );
};
export { FacilityShiftItem };
